<template>
	<section>
		<CCard>
			<CCardBody>
				<div class="gift-detail">
					<div class="gift-detail__heading mb-3"
						><div class="d-flex flex-wrap">
							<h5>{{ $t("CustomerTitle.CustomerGiftDetail") }}</h5>
						</div></div
					>

					<div class="gift-detail__container">
						<div class="info mb-4">
							<div class="info__group">
								<label class="info__group__label">{{ $t("CustomerGifts.CustomerName") }}: </label>
								<div class="info__group__value">{{ get(recentGift, "fullName") }}</div>
							</div>
							<div class="info__group">
								<label class="info__group__label">{{ $t("CustomerGifts.PhoneNumber") }}: </label>
								<div class="info__group__value">{{ get(recentGift, "phoneNumber") }}</div>
							</div>
							<div class="info__group">
								<label class="info__group__label">{{ $t("CustomerGifts.BirthYear") }}: </label>
								<div class="info__group__value">{{ get(recentGift, "birthYear") }}</div>
							</div>
							<div class="info__group">
								<label class="info__group__label">{{ $t("CustomerGifts.EvaluationDate") }}: </label>
								<div class="info__group__value">{{
									getTimeLocale(get(recentGift, "createdAt"), (format = "DD/MM/YYYY"))
								}}</div>
							</div>
						</div>

						<!-- Service -->
						<div class="form-group">
							<label class="form-group__label">{{ $t("BookingQuestion3.Question") }}</label>
							<div class="form-group__value"
								><p v-for="service in get(recentGift, 'services')" :key="service">{{
									getServiceText(service)
								}}</p></div
							>
							<a class="form-group__link" @click="viewOptions">{{
								$t("CustomerGifts.ViewOptions")
							}}</a>
							<div class="form-group__options d-none">
								<p v-for="answer in answersService" :key="answer">{{ answer }}</p>
							</div>
						</div>

						<!-- Receive method -->
						<div v-if="isHNService" class="form-group">
							<label class="form-group__label">{{ $t("BookingQuestion4.Question") }}</label>
							<div class="form-group__value">{{
								labelReceiveMethod(recentGift.receiveMethod)
							}}</div>
							<a class="form-group__link" @click="viewOptions">{{
								$t("CustomerGifts.ViewOptions")
							}}</a>
							<div class="form-group__options d-none">
								<p v-for="answer in answersReceive" :key="answer">{{ answer }}</p>
							</div>
						</div>

						<!-- Address -->
						<div v-if="isHNService" class="form-group">
							<label class="form-group__label">{{ $t("ProductBooking.DeliveryAddress") }}</label>
							<div class="form-group__value">{{ recentGift.receiveAddress }}</div>
						</div>

						<!-- PrEP -->
						<div v-if="isPrEPService" class="form-group">
							<label class="form-group__label">{{ $t("BookingQuestion6.Question") }}</label>
							<div class="form-group__value">{{ labelClinic(recentGift.clinic) }}</div>
							<a class="form-group__link" @click="viewOptions">{{
								$t("CustomerGifts.ViewOptions")
							}}</a>
							<div class="form-group__options d-none">
								<p v-for="answer in answersClinic" :key="answer">{{ answer }}</p>
							</div>
						</div>
					</div>

					<div class="gift-detail__action text-center">
						<CButton v-bind="{ variant: 'outline' }" color="danger" @click="goToCustomerDetail">{{
							$t("Button.Close")
						}}</CButton>
					</div>
				</div>
			</CCardBody>
		</CCard>
	</section>
</template>

<script>
import { BOOKING_ORAQUICK, BOOKING_CLINIC, BOOKING_SERVICES } from "@/shared/plugins/constants"
import { GET_GIFT_DETAIL } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("customers")
import { get } from "lodash-es"

export default {
	name: "CustomerGiftDetail",

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
	},

	data() {
		return {
			answersService: [
				this.$t("BookingQuestion3.Answer1"),
				this.$t("BookingQuestion3.Answer2"),
				this.$t("BookingQuestion3.Answer3"),
			],

			answersReceive: [
				this.$t("BookingQuestion4.Answer1"),
				this.$t("BookingQuestion4.Answer2"),
				this.$t("BookingQuestion4.Answer3"),
			],

			answersClinic: [this.$t("BookingQuestion6.Answer1"), this.$t("BookingQuestion6.Answer2")],
		}
	},

	computed: {
		...mapState(["recentGift"]),

		isHNService: function () {
			return this.recentGift?.services?.includes(BOOKING_SERVICES.HA_NOI)
		},

		isPrEPService: function () {
			return this.recentGift?.services?.includes(BOOKING_SERVICES.FREE_PREP)
		},
	},

	watch: {},

	created() {
		this.GET_GIFT_DETAIL(this.id)
	},

	methods: {
		get,

		...mapActions({ GET_GIFT_DETAIL }),

		getServiceText(service) {
			return this.$t(`ServiceText.${service}`)
		},

		goToCustomerDetail() {
			this.$router.go(-1)
		},

		labelReceiveMethod(value) {
			return {
				[BOOKING_ORAQUICK.POST]: this.$t("BookingQuestion4.Answer1"),
				[BOOKING_ORAQUICK.GRAB]: this.$t("BookingQuestion4.Answer2"),
				[BOOKING_ORAQUICK.CLINIC]: this.$t("BookingQuestion4.Answer3"),
			}[value]
		},

		labelClinic(value) {
			return {
				[BOOKING_CLINIC.GLINK]: this.$t("BookingQuestion6.Answer1"),
				[BOOKING_CLINIC.BIEN_VIET]: this.$t("BookingQuestion6.Answer2"),
			}[value]
		},

		viewOptions($event) {
			const target = $event.target
			target.classList.toggle("d-none")
			target.nextElementSibling.classList.toggle("d-block")
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.gift-detail {
	&__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__container {
		display: grid;
		width: 100%;
		margin-bottom: 15px;
		background-color: $color-white;
		border-radius: 12px;
	}
}

.info {
	max-width: 400px;
	padding: 1rem;
	background: $color-city-light;
	border-radius: 15px;

	&__group {
		display: flex;
		flex-wrap: wrap;
		gap: 4%;

		&__label {
			flex: 0 0 43%;
			max-width: 43%;

			@media (min-width: $xxs) {
				flex: 0 0 33%;
				max-width: 33%;
			}
		}
		&__value {
			flex: 0 0 43%;
			max-width: 43%;

			@media (min-width: $xxs) {
				flex: 0 0 63%;
				max-width: 63%;
			}
		}
	}
}

.form-group {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 20px;

	@media (max-width: $xxs) {
		grid-template-columns: 1fr;
	}

	&__label {
		color: $color-black-pearl;
		font-weight: 500;
	}

	&__link {
		color: $base-color;
		text-decoration: underline;
		&:hover {
			color: $base-color !important;
			cursor: pointer;
			text-decoration: underline;
		}
	}

	&__link,
	&__options {
		@media (max-width: $xxs) {
			display: none;
		}
	}
}
</style>
